// Entry point for the build script in your package.json
import 'jquery-ui';
import Rails from '@rails/ujs';
Rails.start();

import './theme/libs/popper'
import './theme/libs/bootstrap'
import './theme/libs/node-waves'
import './theme/libs/swiper'
import './theme/libs/nouislider'
import "./controllers";
import './theme/helpers'
import './theme/template-customizer'
import './theme/front-config'
import './theme/dropdown-hover'
import './theme/front-main'
import './theme/front-page-landing'
import './sanity/content-renderer'
import './hcaptcha'
