import { Controller } from "@hotwired/stimulus";
import validator from "validator";

export default class extends Controller {
  static targets = ["email", "password", "remember"];

  connect() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleSubmit(event) {
    event.preventDefault();

    let isValid = true;

    if (!this.validateEmail()) isValid = false;
    if (!this.validatePassword()) isValid = false;

    if (isValid) {
      this.element.submit();
    }
  }

  validateEmail() {
    const emailInput = this.emailTarget; // Fixed target name
    if (validator.isEmpty(emailInput.value.trim())) {
      this.showError(emailInput, "Email is required");
      return false;
    } else if (!validator.isEmail(emailInput.value.trim())) {
      this.showError(emailInput, "Enter a valid email address");
      return false;
    } else {
      this.clearError(emailInput);
      return true;
    }
  }

  validatePassword() {
    const passwordInput = this.passwordTarget; // Fixed target name
    if (validator.isEmpty(passwordInput.value.trim())) {
      this.showError(passwordInput, "Password is required");
      return false;
    } else {
      this.clearError(passwordInput);
      return true;
    }
  }

  showError(input, message) {
    const errorElement = input.nextElementSibling;
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.classList.add("text-danger");
    }
    input.classList.add("is-invalid");
  }

  clearError(input) {
    const errorElement = input.nextElementSibling;
    if (errorElement) {
      errorElement.textContent = "";
      errorElement.classList.remove("text-danger");
    }
    input.classList.remove("is-invalid");
  }
}