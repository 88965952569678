/**
 * Config
 * -------------------------------------------------------------------------------------
 * ! IMPORTANT: Make sure you clear the browser local storage In order to see the config changes in the template.
 * ! To clear local storage: (https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/).
 */

'use strict';

window.assetsPath = document.documentElement.getAttribute('data-assets-path');
window.templateName = document.documentElement.getAttribute('data-template');
window.light_core_css_path = document.documentElement.getAttribute('data-light-core-path');
window.dark_core_css_path = document.documentElement.getAttribute('data-dark-core-path');
window.light_theme_css_path = document.documentElement.getAttribute('data-light-theme-path');
window.dark_theme_css_path = document.documentElement.getAttribute('data-dark-theme-path');
window.rtlSupport = false; // set true for rtl support (rtl + ltr), false for ltr only.

/**
 * TemplateCustomizer settings
 * -------------------------------------------------------------------------------------
 * cssPath: Core CSS file path
 * themesPath: Theme CSS file path
 * displayCustomizer: true(Show customizer), false(Hide customizer)
 * lang: To set default language, Add more langues and set default. Fallback language is 'en'
 * controls: [ 'rtl', 'style', 'headerType', 'contentLayout', 'layoutCollapsed', 'layoutNavbarOptions', 'themes' ] | Show/Hide customizer controls
 * defaultStyle: 'light', 'dark', 'system' (Mode)
 * defaultTextDir: 'ltr', 'rtl' (rtlSupport must be true for rtl mode)
 */

if (typeof TemplateCustomizer !== 'undefined') {
  window.templateCustomizer = new TemplateCustomizer({
    cssPath: assetsPath + 'theme-mode' + (rtlSupport ? '/rtl' : '') + '/',
    themesPath: assetsPath + 'theme-mode' + (rtlSupport ? '/rtl' : '') + '/',
    lightCoreCssPath: light_core_css_path,
    darkCoreCssPath: dark_core_css_path,
    lightThemeCssPath: light_theme_css_path,
    darkThemeCssPath: dark_theme_css_path,
    displayCustomizer: false,
    defaultTextDir: 'ltr',
    defaultContentLayout: 'layout-compact',
    defaultStyle: 'system',
    controls: ['ltr', 'style']
  });
}
