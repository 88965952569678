// app/javascript/controllers/form_authentication_controller.js
import { Controller } from "@hotwired/stimulus";
import validator from "validator";

export default class extends Controller {
  static targets = [
    "organizationName", 
    "email", 
    "password", 
    "phone", 
    "terms"
  ];

  connect() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  validateOrganizationName() {
    if (!this.organizationNameTarget || validator.isEmpty(this.organizationNameTarget.value.trim())) {
      this.showError(this.organizationNameTarget, "Organization Name is required");
      return false;
    } else {
      this.clearError(this.organizationNameTarget);
      return true;
    }
  }

  validateEmail() {
    if (!this.emailTarget || validator.isEmpty(this.emailTarget.value.trim())) {
      this.showError(this.emailTarget, "Email is required");
      return false;
    } else if (!validator.isEmail(this.emailTarget.value.trim())) {
      this.showError(this.emailTarget, "Enter a valid email address");
      return false;
    } else {
      this.clearError(this.emailTarget);
      return true;
    }
  }

  validatePassword() {
    if (!this.passwordTarget || validator.isEmpty(this.passwordTarget.value.trim())) {
      this.showError(this.passwordTarget, "Password is required");
      return false;
    } else if (!validator.isLength(this.passwordTarget.value.trim(), { min: 6 })) {
      this.showError(this.passwordTarget, "Password must be at least 6 characters");
      return false;
    } else {
      this.clearError(this.passwordTarget);
      return true;
    }
  }

  validatePhone() {
    if (!this.phoneTarget || this.phoneTarget.value.trim() === "") {
      this.clearError(this.phoneTarget);
      return true;
    } else if (!/^\+?\d{1,4}?\d{10,15}$/.test(this.phoneTarget.value.trim())) {
      this.showError(this.phoneTarget, "Enter a valid phone number, e.g., +13071236768");
      return false;
    } else {
      this.clearError(this.phoneTarget);
      return true;
    }
  }

  validateTerms() {
    if (!this.termsTarget || !this.termsTarget.checked) {
      this.showError(this.termsTarget, "You must agree to the privacy policy and terms");
      return false;
    } else {
      this.clearError(this.termsTarget);
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let isValid = true;

    if (!this.validateOrganizationName()) isValid = false;
    if (!this.validateEmail()) isValid = false;
    if (!this.validatePassword()) isValid = false;
    if (!this.validatePhone()) isValid = false;
    if (!this.validateTerms()) isValid = false;

    if (isValid) {
      this.element.submit();
    }
  }

  showError(input, message) {
    const errorElement = input.nextElementSibling;
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.classList.add("text-danger");
    }
    input.classList.add("is-invalid");
  }

  clearError(input) {
    const errorElement = input.nextElementSibling;
    if (errorElement) {
      errorElement.textContent = "";
      errorElement.classList.remove("text-danger");
    }
    input.classList.remove("is-invalid");
  }
}
