import {toHTML} from '@portabletext/to-html';

document.addEventListener('DOMContentLoaded', () => {
  const blogContentDiv = document.getElementById('blog-content');
  if (!blogContentDiv) return;

  let portableText;
  try {
    portableText = JSON.parse(blogContentDiv.innerText);
  } catch (error) {
    console.log('Error parsing Portable Text JSON:', error);
    return;
  }

  blogContentDiv.innerHTML = toHTML(portableText);
  blogContentDiv.style.display = 'block';
});