/**
 * Main - Front Pages
 */
'use strict';

window.isRtl = window.Helpers?.isRtl?.() || false;
window.isDarkStyle = window.Helpers?.isDarkStyle?.() || false;

(function () {
  // Button & Pagination Waves effect
  if (typeof Waves !== 'undefined') {
    Waves.init();
    Waves.attach(
      ".btn[class*='btn-']:not(.position-relative):not([class*='btn-outline-']):not([class*='btn-label-'])",
      ['waves-light']
    );
    Waves.attach("[class*='btn-outline-']:not(.position-relative)");
    Waves.attach("[class*='btn-label-']:not(.position-relative)");
    Waves.attach('.pagination .page-item .page-link');
    Waves.attach('.dropdown-menu .dropdown-item');
    Waves.attach('.light-style .list-group .list-group-item-action');
    Waves.attach('.dark-style .list-group .list-group-item-action', ['waves-light']);
    Waves.attach('.nav-tabs:not(.nav-tabs-widget) .nav-item .nav-link');
    Waves.attach('.nav-pills .nav-item .nav-link', ['waves-light']);
  }

  const menu = document.getElementById('navbarSupportedContent'),
    nav = document.querySelector('.landing-navbar'),
    navItemLink = document.querySelectorAll('.navbar-nav .nav-link');

  // Initialised custom options if checked
  setTimeout(function () {
    window.Helpers?.initCustomOptionCheck?.();
  }, 1000);

  // Init BS Tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // If layout is RTL add .dropdown-menu-end class to .dropdown-menu
  if (window.isRtl) {
    window.Helpers?._addClass?.('dropdown-menu-end', document.querySelectorAll('#layout-navbar .dropdown-menu'));
  }

  // Navbar
  window.addEventListener('scroll', () => {
    if (window.scrollY > 10) {
      nav?.classList.add('navbar-active');
    } else {
      nav?.classList.remove('navbar-active');
    }
  });
  window.addEventListener('load', () => {
    if (window.scrollY > 10) {
      nav?.classList.add('navbar-active');
    } else {
      nav?.classList.remove('navbar-active');
    }
  });

  // Function to close the mobile menu
  function closeMenu() {
    menu?.classList.remove('show');
  }

  document.addEventListener('click', function (event) {
    // Check if the clicked element is inside mobile menu
    if (!menu?.contains(event.target)) {
      closeMenu();
    }
  });
  navItemLink.forEach(link => {
    link.addEventListener('click', event => {
      if (!link.classList.contains('dropdown-toggle')) {
        closeMenu();
      } else {
        event.preventDefault();
      }
    });
  });

  // If layout is RTL add .dropdown-menu-end class to .dropdown-menu
  if (window.isRtl) {
    window.Helpers?._addClass?.('dropdown-menu-end', document.querySelectorAll('.dropdown-menu'));
  }

  // Mega dropdown
  const megaDropdown = document.querySelectorAll('.nav-link.mega-dropdown');
  if (megaDropdown) {
    megaDropdown.forEach(e => {
      new MegaDropdown(e);
    });
  }

  //Style Switcher (Light/Dark/System Mode)
  let styleSwitcher = document.querySelector('.dropdown-style-switcher');
  const activeStyle = document.documentElement.getAttribute('data-style');

  // Get style from local storage or use 'system' as default
  let storedStyle =
    localStorage.getItem('templateCustomizer-' + templateName + '--Style') || 
    (window.templateCustomizer?.settings?.defaultStyle ?? 'light');

  // Set style on click of style switcher item if template customizer is enabled
  if (window.templateCustomizer && styleSwitcher) {
    let styleSwitcherItems = [].slice.call(styleSwitcher.children[1].querySelectorAll('.dropdown-item'));
    styleSwitcherItems.forEach(function (item) {
      item.classList.remove('active');
      item.addEventListener('click', function () {
        let currentStyle = this.getAttribute('data-theme');
        window.templateCustomizer.setStyle(currentStyle);
      });
      setTimeout(() => {
        if (item.getAttribute('data-theme') === activeStyle) {
          item.classList.add('active');
        }
      }, 1000);
    });

    // Update style switcher icon based on the stored style
    const styleSwitcherIcon = styleSwitcher.querySelector('i');
    styleSwitcherIcon?.classList.add(
      storedStyle === 'light' ? 'ri-sun-line' :
      storedStyle === 'dark' ? 'ri-moon-clear-line' : 'ri-computer-line'
    );
    new bootstrap.Tooltip(styleSwitcherIcon, {
      title: storedStyle.charAt(0).toUpperCase() + storedStyle.slice(1) + ' Mode',
      fallbackPlacements: ['bottom']
    });
  }

  // Run switchImage function based on the stored style
  switchImage(storedStyle);

  // Update light/dark image based on current style
  function switchImage(style) {
    if (style === 'system') {
      style = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    const switchImagesList = [].slice.call(document.querySelectorAll('[data-app-' + style + '-img]'));
    switchImagesList.map(imageEl => {
      imageEl.src = imageEl.getAttribute('data-app-' + style + '-img');
    });
  }

  // Accordion active class
  const accordionActiveFunction = function (e) {
    const accordionItem = e.target.closest('.accordion-item');
    if (e.type === 'show.bs.collapse') {
      accordionItem?.classList.add('active');
      accordionItem?.previousElementSibling?.classList.add('previous-active');
    } else {
      accordionItem?.classList.remove('active');
      accordionItem?.previousElementSibling?.classList.remove('previous-active');
    }
  };

  const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
  accordionTriggerList.forEach(accordionTriggerEl => {
    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
  });
})();
