import Rails from '@rails/ujs';

window.onCaptchaSuccess = (token) => {
  $('#contact-btn').removeAttr('disabled');
};

document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('contact-form');

  form.addEventListener('submit', (event) => {
    event.preventDefault();

    const captchaResponse = hcaptcha.getResponse();
    if (!captchaResponse) {
      $('#captcha-error-alert').removeClass('d-none').text('Please complete the hCaptcha verification.');
    } else {
      $('#captcha-error-alert').addClass('d-none');

      Rails.ajax({
        url: form.action,
        type: form.method,
        data: new FormData(form),
        success: function(response) {
          $('#captcha-success-alert').removeClass('d-none').text('Your message has been sent successfully.');
          form.reset();
          hcaptcha.reset();
        },
        error: function(response) {
          $('#captcha-error-alert').removeClass('d-none').text('There was an error sending your message. Please try again.');
          form.reset();
          hcaptcha.reset();
        }
      });
    }
  });
});